import React from 'react';
import Page from '../../components/layout/Page';
import TransactionsList from '../../components/containers/TransactionsList';

const TransactionsPage: React.FC = () => {
  return (
    <Page title='Transactions' maxWidth='xl'>
      <TransactionsList />
    </Page>
  );
};

export default TransactionsPage;