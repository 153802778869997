import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import SignOutButton from './auth/signout';
import Box from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';
import { styled } from '@mui/material';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  borderRadius: 0,
}));

type HeaderProps = {
  maxWidth?: ContainerProps['maxWidth'];
};

const Header: React.FC<HeaderProps> = ({ maxWidth }) => {
  return ( 
    <AppBar position="static">
      <Container maxWidth={maxWidth}>
        <Toolbar sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6" component="div" color='primary'>
            Admin Panel
          </Typography>
          <Button color="inherit" component={Link} to="/companies">
            Companies
          </Button>
          <Button color="inherit" component={Link} to="/admins">
            Admins
          </Button>
          <Button color="inherit" component={Link} to="/transactions">
            Transactions
          </Button>
          <Button color="inherit" component={Link} to="/tons">
            Wallet
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <SignOutButton />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;