import { Admin, AdminData } from '../types/admin';
import cfg from '../config';
import { getToken } from './auth';

export const urls = {
  List: '/api/v1/admins',
  Approve: (id: string) => `/api/v1/admins/${id}/approve`,
  Reject: (id: string) => `/api/v1/admins/${id}/reject`
};

export const list = async (): Promise<Admin[]> => {
  const response = await fetch(`${cfg.apiUrl}${urls.List}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const approve = async (id: string): Promise<void> => {
  const response = await fetch(`${cfg.apiUrl}${urls.Approve(id)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export const reject = async (id: string): Promise<void> => {
  const response = await fetch(`${cfg.apiUrl}${urls.Reject(id)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}