import React, { createContext, useContext, useEffect, useState } from 'react';
import { Company } from '../../types/company';
import * as api from '../../api/company'; 
import useSWR from 'swr';

// Define the company context
interface CompanyContextType {
  companyId: string;
  company?: Company;
}

// Create the company context
const CompanyContext = createContext<CompanyContextType | undefined>(undefined);

// Create the CompanyProvider component
interface CompanyProviderProps {
  id: string;
  children: React.ReactNode;
}

const CompanyProvider: React.FC<CompanyProviderProps> = ({ id, children }) => {
  const { data: company } = useSWR<Company>(id ? api.utrls.Get(id) : null);
  return (
    <CompanyContext.Provider value={{ companyId: id, company }}>
      {children}
    </CompanyContext.Provider>
  );
};

// Custom hook to access the company context
const useCompany = (): CompanyContextType => {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error('useCompany must be used within a CompanyProvider');
  }

  return context;
};

export { CompanyProvider, useCompany };
