import React from 'react';
import { Toolbar } from '@mui/material';
import { useParams } from 'react-router-dom';
import Page from '../../../components/layout/Page';
import { CompanyProvider } from '../../../components/providers/CompanyProvider';
import RegisterModelButton from '../../../components/containers/model/RegisterModelButton';
import ModelList from '../../../components/containers/model/ModelList';
import CompanyBalance from '../../../components/containers/company/CompanyBalance';

const CompanyModels: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <CompanyProvider id={id as string}>
      <Page title='Models'>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <RegisterModelButton companyId={id as string} />
          <CompanyBalance>
            Balance
          </CompanyBalance>
        </Toolbar>
        <ModelList />
      </Page>
    </CompanyProvider>
  );
};

export default CompanyModels;