import React from 'react';
import Page from '../components/layout/Page';
import { Toolbar } from '@mui/material';
import AddCompanyButton from '../components/containers/company/AddCompanyButton';
import CompanyList from '../components/containers/company/CompanyList';


const Companies: React.FC = () => {
  return (
    <Page>
      <Toolbar>
        <AddCompanyButton />
      </Toolbar>
      <CompanyList />
    </Page>
  );
};

export default Companies;