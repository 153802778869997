import React from 'react';
import { Container, Toolbar } from '@mui/material';
import { useParams } from 'react-router-dom';
import Page from '../../../../components/layout/Page';
import { CompanyProvider } from '../../../../components/providers/CompanyProvider';
import ModelList from '../../../../components/containers/model/ModelList';

const AdminCompanyModels: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <CompanyProvider id={id as string}>
      <Page title='Models'>
        <Container maxWidth='md'>
          <ModelList admin />
        </Container>
      </Page>
    </CompanyProvider>
  );
};

export default AdminCompanyModels;