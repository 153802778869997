import { User } from '../types/user';
import cfg from '../config';
import { getToken } from './auth';

export const urls = {
  List: '/api/v1/users',
  Get: (id: string) => `/api/v1/users/${id}`,
};

export const list = async (): Promise<User[]> => {
  const response = await fetch(`${cfg.apiUrl}${urls.List}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}