import React, { useEffect } from 'react';
import Page from '../components/layout/Page';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';


const Home: React.FC = () => {
  // redirect to companies page
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/companies');
  }, [navigate]);
  return (
    <Page title='PayMes'>
      <Typography variant='h1'>Welcome to PayMes</Typography>
    </Page>
  );
};

export default Home;