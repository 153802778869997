
import cfg from '../config';
import { UsdtTransaction } from '../types/usdt';
import { getToken } from './auth';

export const urls = {
  List: '/api/v1/usdts/transactions',
};

export const list = async (): Promise<UsdtTransaction[]> => {
  const response = await fetch(`${cfg.apiUrl}${urls.List}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}