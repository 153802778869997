import React from "react";
import { useAuth } from "../../providers/AuthProvider";
import { Button, ButtonProps } from "@mui/material";

const SignOutButton: React.FC<ButtonProps> = (props) => {
  const { logout } = useAuth();

  return (
    <Button
      {...props}
      onClick={() => {
        logout();
      }}
    >
      Sign Out
    </Button>
  );
};

export default SignOutButton;