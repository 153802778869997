import React from 'react';
import useSWR from 'swr';
import { FormControl, InputLabel, MenuItem, Select, FormControlProps } from '@mui/material';
import * as api from '../../../api/company';
import { Company } from '../../../types/company';

type CompanySelectProps = Omit<FormControlProps, 'onChange'> & {
  value: string;
  onChange: (event: React.ChangeEvent<{ value: string }>) => void;
};
const CompanySelect: React.FC<CompanySelectProps> = ({ value, onChange }) => {
  const { data = [] } = useSWR<Company[]>(api.utrls.List);
  const handleChange = (event: any) => {
    onChange(event as React.ChangeEvent<{ value: string }>);
  };


  return (
    <FormControl fullWidth>
      <InputLabel id="company-select-label">Company</InputLabel>
      <Select
        labelId="company-select-label"
        value={value}
        onChange={handleChange}
      >
        {data.map(company => (
          <MenuItem key={company.id} value={company.id}>
            {company.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CompanySelect;
