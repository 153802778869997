import React from 'react'
import cfg from '../../config';
import { SWRConfig } from 'swr';
import { getToken } from '../../api/auth';


const defaultFetcher = async(resource: string, init: any) => {
  // ${cfg.serviceUrl}
  const url = !resource.includes('http') ? `${cfg.apiUrl}${resource}` : resource;
  const res = await fetch(url, {
    ...init,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getToken()}`,
    },
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  const result = await res.json();

  return result;
};

type SWRProviderProps = {
  children: React.ReactNode,
}

const SWRProvider: React.FC<SWRProviderProps> = (props) => {
  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher: defaultFetcher,
      }}
    >
      {props.children}
    </SWRConfig>
  );
}

export default SWRProvider
