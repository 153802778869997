import React from 'react';
import useSWR from 'swr';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { User } from '../../types/user';
import { green, red, yellow } from '@mui/material/colors';
import { Delete } from '@mui/icons-material';
import ExternalLink from '../dumps/ExternalLink';
import useTransactions from '../../hooks/useTransactions';
import { shortHash } from '../../utils';

const titleName = (user: User) => `${user.name}#${user.id.slice(-4)}`; 

const TransactionsList: React.FC = () => {
  const { transactions, reset, remove } = useTransactions();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>From User Name</TableCell>
            <TableCell>From Wallet</TableCell>
            <TableCell>Hash</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction) => (
            <TableRow key={transaction.id}>
              <TableCell>{new Date(transaction.date).toLocaleString()}</TableCell>
              <TableCell>{transaction.user ? titleName(transaction.user) : 'Unknown'}</TableCell>
              <TableCell>
                <ExternalLink href={`https://tonscan.com/accounts/${encodeURIComponent(transaction.senderAddress)}`}>
                  {transaction.senderAddress}
                </ExternalLink>
              </TableCell>
              <TableCell>
                <Tooltip title={transaction.txHash}>
                  <ExternalLink href={`https://tonscan.com/transactions/${encodeURIComponent(transaction.txHash)}`}>
                    {shortHash(transaction.txHash)}
                  </ExternalLink>
                </Tooltip>
              </TableCell>
              <TableCell>
                {`${transaction.amount}T by ${transaction.actualPrice}TON`}
              </TableCell>
              <TableCell>
                <Tooltip title={transaction.statusMessage || ''}>
                  {
                    transaction.status === 'failed' ? (
                      <Button color='error' onClick={() => reset(transaction.id)}>
                        {transaction.status}
                      </Button>
                    ) : (
                      <Typography color={transaction.status === 'pending' ? yellow[700] : transaction.status === 'success' ? green[700] : red[700]}>
                        {transaction.status}
                      </Typography>
                    )
                  }
                </Tooltip>
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => remove(transaction.id)}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionsList;
