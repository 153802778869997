import React from 'react';
import AdminList from '../components/containers/AdminList';
import Page from '../components/layout/Page';


const AdminsPage: React.FC = () => {
  return (
    <Page title='Balloons'>
      <AdminList />
    </Page>
  );
};

export default AdminsPage;