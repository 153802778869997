import React from 'react';
import Page from '../components/layout/Page';
import TonsList from '../components/containers/TonsList';
import { Button, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

const TonsPage: React.FC = () => {
  return (
    <Page title='Transactions' maxWidth='xl'>
      <Toolbar>
        <Link to='/tons'>
          <Button variant='contained'>TON</Button>
        </Link>
        <Link to='/usdts'>
          <Button variant='contained'>USDT</Button>
        </Link>
      </Toolbar>
      <TonsList />
    </Page>
  );
};

export default TonsPage;