import cfg from "../config";
import { User } from "../types/user";

const setToken = (token: string) => {
  localStorage.setItem('token', token);
}

export const getToken = () => {
  return localStorage.getItem('token');
}

export const fetchMe = async (): Promise<User> => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/auth/me`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch user');
  }

  // set toke if it was updated
  const data = await response.json();
  if (data.access_token) {
    setToken(data.access_token);
  }

  return data;
}

export const login = async (email: string, password: string): Promise<User> => {
  const response = await fetch(`${cfg.apiUrl}/api/v1/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });

  if (!response.ok) {
    throw new Error('Failed to login');
  }

  const data = await response.json();
  setToken(data.access_token);

  return data.user;
}

export const logout = () => {
  localStorage.removeItem('token');
}