import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps
} from '@mui/material';

type AcceptDialogProps = Omit<DialogProps, 'onClose'> & {
  title: string;
  description: string;
  onClose: () => void;
  onAccept: () => void;
};

const AcceptDialog: React.FC<AcceptDialogProps> = ({ onAccept, title, description, onClose, ...props }) => {
  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {description}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onAccept}>Accept</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptDialog;
