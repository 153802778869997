import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from './components/providers/ThemeProvider';
import SWRProvider from './components/providers/SWRProvider';
import AuthProvider from './components/providers/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';
import StartPage from './pages';
import CompaniesPage from './pages/companies';
import CompanyPage from './pages/company/[id]';
import CompanyModelsPage from './pages/company/[id]/models';
import AdminCompanyModelsPage from './pages/admin/company/[id]/models';
import LoginPage from './pages/siginin';
import AdminsPage from './pages/admins';
import TransactionsPage from './pages/transactions';
import TransactionPage from './pages/transactions/[hash]';
import TonsPage from './pages/tons';
import UsdtsPage from './pages/usdts';

function App() {
  return (
    <Router>
      <SWRProvider>
        <ThemeProvider>
          <AuthProvider>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {/* @ts-ignore */}
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<StartPage />} />
                <Route path="/companies" element={<CompaniesPage />} />
                <Route path="/company/:id" element={<CompanyPage />} />
                <Route path="/company/:id/models" element={<CompanyModelsPage />} />
                <Route path="/admin/company/:id/models" element={<AdminCompanyModelsPage />} />
                <Route path="/admins" element={<AdminsPage />} />
                <Route path="/transactions/:hash" element={<TransactionPage />} />
                <Route path="/transactions" element={<TransactionsPage />} />
                <Route path="/tons" element={<TonsPage />} />
                <Route path="/usdts" element={<UsdtsPage />} />
              </Route>   
            </Routes>
          </AuthProvider>
        </ThemeProvider>
      </SWRProvider>
    </Router>
  );
}

export default App;
