import React from 'react';
import useSWR from 'swr';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import * as api from '../../api/usdts';
import { green, red, yellow } from '@mui/material/colors';
import { Delete } from '@mui/icons-material';
import ExternalLink from '../dumps/ExternalLink';
import { Link } from 'react-router-dom';
import { shortHash } from '../../utils'; // P711c
import { UsdtTransaction } from '../../types/usdt';

function time2date(time: number) {
  return new Date(time * 1000).toLocaleString();
}

function shortMessage(message: string) {
  return message.length > 32 ? `${message.slice(0, 32)}...` : message;
}

function IsMessageUUID(uuid: string) {
  return uuid.length === 32;
}

const useUsdt = () => {
  const { data: usdts = [], error, mutate } = useSWR<UsdtTransaction[]>(api.urls.List);
  return {
    usdts,
    error,
    mutate
  };
}
// <ExternalLink href={`https://tonscan.com/transactions/${encodeURIComponent(transaction.txHash)}`}>
const UsdtList: React.FC = () => {
  const { usdts } = useUsdt();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell>Sender Address</TableCell>
            <TableCell>Destination Address</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Transaction Hash</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usdts.map((usdt, index) => (
            <TableRow key={index}>
              <TableCell>{time2date(usdt.block_timestamp)}</TableCell>
              <TableCell>
                <ExternalLink href={`https://tonscan.com/accounts/${encodeURIComponent(usdt.from)}`}>
                  {shortHash(usdt.from)}
                </ExternalLink>
              </TableCell>
              <TableCell>
                <ExternalLink href={`https://tonscan.com/accounts/${encodeURIComponent(usdt.to)}`}>
                  {shortHash(usdt.to)}
                </ExternalLink>
              </TableCell>
              <TableCell>{usdt.value} USDT</TableCell>
              <TableCell>
                {IsMessageUUID(usdt.transaction_id) ? (
                  <Link to={`/transactions/${encodeURIComponent(usdt.transaction_id)}`}>
                    {usdt.transaction_id}
                  </Link>
                ) : shortMessage(usdt.transaction_id)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsdtList;
