import React, { createContext, useContext, useEffect, useState } from 'react';
import { User } from '../../types/user';
import * as api from '../../api/auth';

// Define the auth context
interface AuthContextType {
  user?: User;
  error?: string;
  loading: boolean;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

// Create the auth context
const AuthContext = createContext<AuthContextType>({
  user: undefined,
  error: undefined,
  loading: true,
  isAuthenticated: true,
  login: async () => {},
  logout: () => {},
});

// Create the AuthProvider component
interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  console.log('AuthProvider', user)
  useEffect(() => {
    api.fetchMe()
      .then((user) => setUser(user))
      .catch(() => setError('Failed to fetch user'))
      .finally(() => setLoading(false));
  }, []);

  const login = async (email: string, password: string) => {
    const user = await api.login(email, password);
    setUser(user);
  };

  const logout = () => {
    api.logout();
    setUser(undefined);
  };

  const isAuthenticated = !!user

  return (
    <AuthContext.Provider value={{
      user,
      error,
      loading,
      isAuthenticated,
      login,
      logout,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access the auth context
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export { AuthProvider, useAuth };
export default AuthProvider;