import React, { useState } from 'react';
import useSWR from 'swr';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  IconButton,
  Button,
  Typography,
  ListProps,
  Switch,
  FormControlLabel,
  ListItemProps,
} from '@mui/material';
import * as api from '../../../api/model';
import { Model } from '../../../types/model';
import AcceptDialog from '../../dialogs/AcceptDialog';
import { Delete, Edit } from '@mui/icons-material';
import EditModelDialog from '../../dialogs/EditModelDialog';
import { toast } from 'react-hot-toast';
import BlurPaper from '../../dumps/BlutPaper';
import ApproveModelDialog from '../../dialogs/ApproveModelDialog';
import { red } from '@mui/material/colors';
import { useCompany } from '../../providers/CompanyProvider';
import ExternalLink from '../../dumps/ExternalLink';

type ModelItemProps = ListItemProps & {
  admin?: boolean;
  model: Model;
  onEdit: (model: Model) => void;
  onDelete: (model: Model) => void;
  onApprove: (model: Model) => void;
  onSwitched: (model: Model) => void;
};

const ModelItem: React.FC<ModelItemProps> = ({ admin, model, onEdit, onDelete, onApprove, onSwitched, ...props }) => {
  const handleSwitchActivate = async () => {
    try {
      await toast.promise(api.update(model.id, { isActivated: !model.isActivated }), {
        loading: 'Activating model...',
        success: 'Model activated!',
        error: 'Failed to activate model',
      });
      onSwitched(model);
    } catch (error) {
      console.error('Failed to update model', error);
    }
  }

  const approved = model.botLink && model.telegramBotSecretKey && model.alias;
  return (
    <ListItem {...props}>
      <ListItemAvatar>
        <Avatar src={model.avatarUrl} alt={model.name} />
      </ListItemAvatar>
      <ListItemText primary={model.name} secondary={model.alias || 'No alias'} />
      <ListItemText
        primary={model.botLink ? (
          <ExternalLink href={model.botLink}>
            {model.botLink}
          </ExternalLink>
        ) : 'No bot link'}
        secondary={model.alias ? (
          <ExternalLink href={`https://paymes.kickandig.com/bot/${model.alias}`}>
            {`https://paymes.kickandig.com/bot/${model.alias}`}
          </ExternalLink>
        ) : undefined}
      />
      <ListItemText primary={model.tokens} secondary="Tokens" />
      {
        !approved ? (
          admin ? (
            <Button
              variant="contained"
              onClick={() => onApprove(model)}
            >
              Approve
            </Button>
          ) : (
            <Typography variant="h6" color="error">
              Waiting for approval
            </Typography>
          )
        ) : (
          <>
            <FormControlLabel
              control={<Switch checked={model.isActivated} onChange={handleSwitchActivate}/>}
              label="Activate"
            />
            <IconButton onClick={() => onEdit(model)}>
              <Edit />
            </IconButton>
          </>
        )
      }
      <IconButton onClick={() => onDelete(model)}>
        <Delete />
      </IconButton>
    </ListItem>
  )
};


type ModelListProps = ListProps & {
  admin?: boolean;
};

const ModelList: React.FC<ModelListProps> = ({ admin = false, ...props }) => {
  const { companyId } = useCompany();
  const { data, error, mutate } = useSWR<Model[]>(api.utrls.ListQuery({ companyId }));
  const [deleteModel, setDeleteModel] = useState<Model | null>(null);
  const [editModel, setEditModel] = useState<Model | null>(null);
  const [approveModel, setApproveModel] = useState<Model | null>(null);

  const handleDelete = async () => {
    if (deleteModel) {
      try {
        await toast.promise(api.remove(deleteModel.id), {
          loading: 'Deleting model...',
          success: 'Model deleted!',
          error: 'Failed to delete model',
        });
        // Refresh the data after deleting
        mutate();
      } catch (error) {
        console.error('Failed to delete model', error);
      }
    }
    setDeleteModel(null);
  };

  const handleEdit = async (model: Model) => {
    try {
      await toast.promise(api.update(model.id, model), {
        loading: 'Updating model...',
        success: 'Model updated!',
        error: 'Failed to update model',
      });
      mutate();
    } catch (error) {
      console.error('Failed to update model', error);
    }
    setEditModel(null);
    setApproveModel(null);
  };



  const handleDeleteDialogOpen = (model: Model) => setDeleteModel(model);
  const handleDeleteDialogClose = () => setDeleteModel(null);

  const handleEditDialogOpen = (model: Model) => setEditModel(model);
  const handleEditDialogClose = () => setEditModel(null);

  const handleApproveDialogOpen = (model: Model) => setApproveModel(model);
  const handleApproveDialogClose = () => setApproveModel(null);

  const handleSwitched = () => mutate();

  if (error) return <div>Failed to load models</div>;
  if (!data) return <CircularProgress />;

  return (
    <>
      <List {...props}>
        {data.map((model, index) => {
          const approved = model.botLink && model.telegramBotSecretKey && model.alias;
          return (
            <BlurPaper key={model.id} duration={`${index * 0.1}s`} background={!approved ? red[200] : undefined}>
              <ModelItem
                admin={admin}
                model={model}
                onEdit={handleEditDialogOpen}
                onDelete={handleDeleteDialogOpen}
                onApprove={handleApproveDialogOpen}
                onSwitched={handleSwitched}
              />
            </BlurPaper>
          );
        })}
      </List>
      <AcceptDialog
        open={deleteModel !== null}
        onClose={handleDeleteDialogClose}
        onAccept={handleDelete}
        title="Delete Model"
        description="Are you sure you want to delete this model?"
      />
      {
        editModel !== null && (
          <EditModelDialog
            open={editModel !== null}
            onClose={handleEditDialogClose}
            onAccept={handleEdit}
            init={editModel}
          />
        )
      }
      {
        approveModel !== null && (
          <ApproveModelDialog
            open={approveModel !== null}
            onClose={handleApproveDialogClose}
            onAccept={handleEdit}
            init={approveModel}
          />
        )
      }
    </>
  );
};

export default ModelList;
