import React from 'react';
import Page from '../../components/layout/Page';
import { useTransactionByHash } from '../../hooks/useTransactions';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography } from '@mui/material';
import { Transaction } from '../../types/transaction';
import { User } from '../../types/user';
import { titleName } from '../../lib/user';


const TransactionCard = ({ transaction }: { transaction: Transaction & { user?: User } }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant='h5' component='h2'>
          {transaction.txHash}
        </Typography>
        <Typography color='textSecondary'>
          {new Date(transaction.date).toLocaleString()}
        </Typography>
        <Typography variant='body2' component='p'>
          From User Name: {transaction.user ? titleName(transaction.user) : 'Unknown'}
        </Typography>
        <Typography variant='body2' component='p'>
          From Wallet: {transaction.senderAddress}
        </Typography>
        <Typography variant='body2' component='p'>
          Amount: {`${transaction.amount}T by ${transaction.actualPrice}TON`}
        </Typography>
        <Typography variant='body2' component='p'>
          Status: {transaction.status}
        </Typography>
      </CardContent>
    </Card>
  );
}


const TransactionPage: React.FC = () => {
  const { hash } = useParams<{ hash: string }>();
  const { transaction, isLoading } = useTransactionByHash(hash as string);
  return (
    <Page title='Transactions' maxWidth='xl'>
      {isLoading ? 'Loading...' : transaction ? <TransactionCard transaction={transaction} /> : 'Not found'}
    </Page>
  );
};

export default TransactionPage;