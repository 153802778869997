import { Container, ContainerProps } from '@mui/material';
import React from 'react'
import { Toaster } from "react-hot-toast";
import Header from '../containers/Header';

type Props = ContainerProps & {
  children?: React.ReactNode,
}

const Page = ({ children, sx, ...props }: Props) => {
  return (
    <div>
      <Header maxWidth={props.maxWidth} />
      <Container component='main' sx={{ py: 4, ...sx }} {...props}>
        {children}
      </Container>
      <Toaster toastOptions={{ position: "bottom-center" }} />
    </div>
  )
}

export default Page
