import React, { useState } from 'react';
import { mutate } from 'swr';
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import * as api from '../../../api/company';
import { toast } from 'react-hot-toast';
import { CompanyData } from '../../../types/company';
import * as _ from 'lodash';

type Data = Omit<CompanyData, 'id' | 'isActivated'>;
const initData = {
  name: '',
  botLink: '',
  telegramBotSecretKey: '',
};

type AddCompanyButtonProps = Omit<ButtonProps, 'onClick'>;
const AddCompanyButton: React.FC<AddCompanyButtonProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Data>(initData);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setData(initData);
  };

  const handleAddCompany = async () => {
    if (data.name.trim() === '') {
      return;
    }


    try {
      await toast.promise(api.create(data), {
        loading: 'Adding company...',
        success: 'Company added!',
        error: 'Failed to add company',
      });

      mutate(api.list);
      handleClose();
    } catch (error) {
      console.error('Failed to add company', error);
    }
  };

  const disabled = _.isEmpty(data.name);
  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen} {...props}>
        Add Company
      </Button>
      <Dialog open={open}>
        <DialogTitle>Add a New Company</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Company Name"
            fullWidth
            required
            value={data.name}
            onChange={(e) => setData(old => ({ ...old, name: e.target.value }))}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Wallet Address"
            fullWidth
            disabled
            value="N/A"
          />
          <TextField
            autoFocus
            margin="dense"
            label="Bot Link"
            fullWidth
            value={data.botLink}
            onChange={(e) => setData(old => ({ ...old, botLink: e.target.value }))}
          />
          <TextField
            autoFocus
            margin="dense"
            label="Telegram Bot Secret Key"
            fullWidth
            value={data.telegramBotSecretKey}
            onChange={(e) => setData(old => ({ ...old, telegramBotSecretKey: e.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant='contained' disabled={disabled}>
            Cancel
          </Button>
          <Button onClick={handleAddCompany} color="primary" variant='contained'>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCompanyButton;
