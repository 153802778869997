
import cfg from '../config';
import { Transaction } from '../types/transaction';
import { getToken } from './auth';

export const urls = {
  List: '/api/v1/transactions',
  Get: (hash: string) => `/api/v1/transactions/hash/${hash}`,
  Approve: (id: string) => `/api/v1/transactions/${id}/approve`,
  Reject: (id: string) => `/api/v1/transactions/${id}/reject`,
  Remove: (id: string) => `/api/v1/transactions/${id}`,
  Reset: (id: string) => `/api/v1/transactions/${id}/reset`,
};

export const list = async (): Promise<Transaction[]> => {
  const response = await fetch(`${cfg.apiUrl}${urls.List}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const approve = async (id: string): Promise<void> => {
  const response = await fetch(`${cfg.apiUrl}${urls.Approve(id)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export const reject = async (id: string): Promise<void> => {
  const response = await fetch(`${cfg.apiUrl}${urls.Reject(id)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export const remove = async (id: string): Promise<void> => {
  const response = await fetch(`${cfg.apiUrl}${urls.Remove(id)}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export const reset = async (id: string): Promise<void> => {
  const response = await fetch(`${cfg.apiUrl}${urls.Reset(id)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}