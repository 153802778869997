import React from "react";
import { useCompany } from "../../providers/CompanyProvider";
import { Model } from "../../../types/model";
import * as api from '../../../api/model';
import useSWR from "swr";
import { Typography, TypographyProps } from "@mui/material";

export type CompanyBalanceProps = TypographyProps;

const CompanyBalance: React.FC<CompanyBalanceProps> = ({ children, ...props }) => {
  const { companyId } = useCompany();
  const { data: models = [], error, mutate } = useSWR<Model[]>(api.utrls.ListQuery({ companyId }));

  const balance = models.reduce((acc, model) => acc + model.tokens, 0);

  return (
    <Typography {...props}>
      {children}: {balance}
    </Typography>
  );
}

export default CompanyBalance;