import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  TextField
} from '@mui/material';
import { Company, CompanyData } from '../../types/company';

type EditCompanyDialogProps = DialogProps & {
  init: Company;
  onClose: () => void;
  onAccept: (data: Company) => void;
}

const EditCompanyDialog: React.FC<EditCompanyDialogProps> = ({ open, onAccept, onClose, init, ...props }) => {
  const [data, setData] = useState<Company>(init);
  useEffect(() => setData(init), [init]);

  return (
    <Dialog open={open} {...props}>
      <DialogTitle>Add a New Model</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          fullWidth
          required
          value={data.name}
          onChange={(e) => setData(old => ({ ...old, name: e.target.value }))}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Wallet Address"
          fullWidth
          disabled
          value="N/A"
        />
        <TextField
          autoFocus
          margin="dense"
          label="Bot Link"
          fullWidth
          value={data.botLink}
          onChange={(e) => setData(old => ({ ...old, botLink: e.target.value }))}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Telegram Bot Secret Key"
          fullWidth
          value={data.telegramBotSecretKey}
          onChange={(e) => setData(old => ({ ...old, telegramBotSecretKey: e.target.value }))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant='contained'>
          Cancel
        </Button>
        <Button onClick={() => onAccept(data)} color="primary" variant='contained'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCompanyDialog;