/*
Example
import { Company, CompanyData } from '../types/company';
import cfg from '../config';
export const utrls = {
  List: '/api/v1/companies',
  Get: (id: string) => `/api/v1/companies/${id}`,
  Create: '/api/v1/companies',
  Update: (id: string) => `/api/v1/companies/${id}`,
  Delete: (id: string) => `/api/v1/companies/${id}`
};

export const list = async (): Promise<Company[]> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.List}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const get = async (id: string): Promise<Company> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Get(id)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const create = async (company: CompanyData): Promise<Company> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Create}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(company)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const update = async (id: string, company: Partial<CompanyData>): Promise<Company> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Update(id)}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(company)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const remove = async (id: string): Promise<Company> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Delete(id)}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}
*/

import { Model, ModelData } from '../types/model';
import cfg from '../config';
import { getToken } from './auth';

export const utrls = {
  List: '/api/v1/models',
  ListQuery: (params: { companyId?: string }) => `/api/v1/models?${new URLSearchParams(params).toString()}`,
  Get: (id: string) => `/api/v1/models/${id}`,
  Create: '/api/v1/models',
  Update: (id: string) => `/api/v1/models/${id}`,
  Delete: (id: string) => `/api/v1/models/${id}`
};

type ListParams = {
  companyId?: string;
}

export const list = async (params: ListParams): Promise<Model[]> => {
  const url = new URL(`${cfg.apiUrl}${utrls.List}`);
  if (params.companyId) {
    url.searchParams.append('companyId', params.companyId);
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const get = async (id: string): Promise<Model> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Get(id)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

type CreatePayload = Omit<ModelData, 'isActivated'>;
export const create = async (model: CreatePayload): Promise<Model> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Create}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    },
    body: JSON.stringify(model)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const update = async (id: string, model: Partial<ModelData>): Promise<Model> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Update(id)}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    },
    body: JSON.stringify(model)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const remove = async (id: string): Promise<Model> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Delete(id)}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}