import React from 'react';
import { useParams } from 'react-router-dom';
import Page from '../../../components/layout/Page';
import { CompanyProvider } from '../../../components/providers/CompanyProvider';

const Company: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <CompanyProvider id={id as string}>
      <Page>
        Company
      </Page>
    </CompanyProvider>
  );
};

export default Company;