import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  TextField
} from '@mui/material';
import CompanySelect from '../containers/company/CompanySelect';
import { Model } from '../../types/model';

function isTelegramUrl(url: string) {
  return url.includes('https://t.me/');
}

function isHttpUrl(url: string) {
  return url.includes('http://') || url.includes('https://');
}

function isNoSpace(str: string) {
  return !str.includes(' ');
}

function validateModel(model: Model) {
  return model.companyId &&
    model.name && model.name.trim() !== '' &&
    model.alias && model.alias.length > 0 && isNoSpace(model.alias) &&
    model.avatarUrl && model.avatarUrl.length > 0 && isHttpUrl(model.avatarUrl) &&
    model.botLink && model.botLink.length > 0 && isTelegramUrl(model.botLink) &&
    model.telegramBotSecretKey && model.telegramBotSecretKey.length > 0 &&
    model.controlBotLink && model.controlBotLink.length > 0 && isTelegramUrl(model.controlBotLink) &&
    model.telegramControlBotSecretKey && model.telegramControlBotSecretKey.length > 0;
}

type ApproveModelDialogProps = DialogProps & {
  init: Model;
  onClose: () => void;
  onAccept: (model: Model) => void;
}

const ApproveModelDialog: React.FC<ApproveModelDialogProps> = ({ open, onAccept, onClose, init, ...props }) => {
  const [data, setData] = useState<Model>(init);
  useEffect(() => setData(init), [init]);

  const disabled = !validateModel(data);
  return (
    <Dialog open={open} {...props}>
      <DialogTitle>Approve Model</DialogTitle>
      <DialogContent>
        <CompanySelect value={data.companyId} onChange={(e) => setData(old => ({ ...old, companyId: e.target.value }))} />
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          fullWidth
          required
          value={data.name}
          onChange={(e) => setData(old => ({ ...old, name: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Alias (Lowercase, no spaces)"
          fullWidth
          required
          value={data.alias}
          onChange={(e) => setData(old => ({ ...old, alias: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Avatar URL (http/https)"
          fullWidth
          required
          value={data.avatarUrl}
          onChange={(e) => setData(old => ({ ...old, avatarUrl: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Bot Link (https://t.me/)"
          fullWidth
          required
          value={data.botLink}
          onChange={(e) => setData(old => ({ ...old, botLink: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Telegram Bot Secret Key"
          required
          fullWidth
          value={data.telegramBotSecretKey}
          onChange={(e) => setData(old => ({ ...old, telegramBotSecretKey: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Control Bot Link (https://t.me/)"
          fullWidth
          required
          value={data.controlBotLink}
          onChange={(e) => setData(old => ({ ...old, controlBotLink: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Telegram Control Bot Secret Key"
          fullWidth
          required
          value={data.telegramControlBotSecretKey}
          onChange={(e) => setData(old => ({ ...old, telegramControlBotSecretKey: e.target.value }))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant='contained'>
          Cancel
        </Button>
        <Button onClick={() => onAccept(data)} color="primary" variant='contained' disabled={disabled}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ApproveModelDialog;