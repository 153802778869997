import { Company, CompanyData } from '../types/company';
import cfg from '../config';
import { getToken } from './auth';
export const utrls = {
  List: '/api/v1/companies',
  Get: (id: string) => `/api/v1/companies/${id}`,
  Create: '/api/v1/companies',
  Update: (id: string) => `/api/v1/companies/${id}`,
  Delete: (id: string) => `/api/v1/companies/${id}`
};

export const list = async (): Promise<Company[]> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.List}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getToken()}`,
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const get = async (id: string): Promise<Company> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Get(id)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getToken()}`,
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

type CreatePayload = Omit<CompanyData, 'isActivated'>;
export const create = async (company: CreatePayload): Promise<Company> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Create}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getToken()}`,
    },
    body: JSON.stringify(company)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const update = async (id: string, company: Partial<CompanyData>): Promise<Company> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Update(id)}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getToken()}`,
    },
    body: JSON.stringify(company)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}

export const remove = async (id: string): Promise<Company> => {
  const response = await fetch(`${cfg.apiUrl}${utrls.Delete(id)}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getToken()}`,
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json()
}