import useSWR from 'swr';
import { Transaction } from '../types/transaction';
import * as api from '../api/transaction';
import * as userApi from '../api/user';
import { User } from '../types/user';

export function useTransactionByHash(hash: string) {
  const { data: transaction, error, mutate, isLoading } = useSWR<Transaction>(api.urls.Get(hash));
  const { data: user } = useSWR<User>(transaction ? userApi.urls.Get(transaction?.userId) : null);
  return {
    transaction: transaction ? { ...transaction, user } : null,
    error,
    mutate,
    isLoading,
  };
}

// join user to transaction
export function useTransactions() {
  const { data: transactions = [], error, mutate } = useSWR<Transaction[]>(api.urls.List);
  const { data: users = [] } = useSWR<User[]>(userApi.urls.List);

  const joined = transactions.map((transaction) => {
    const user = users.find((user) => user.id === transaction.userId);
    return { ...transaction, user };
  });

  return {
    transactions: joined,
    error,
    //approve: api.approve,
    //reject: api.reject,
    reset: (id: string) => api.reset(id).then(() => mutate()),
    remove: (id: string) => api.remove(id).then(() => mutate()),
    mutate
  };
}

export default useTransactions;