import React from 'react';
import useSWR from 'swr';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
} from '@mui/material';
import { Admin } from '../../types/admin';
import * as adminApi from '../../api/admin';
import * as modelApi from '../../api/model';
import { Model } from '../../types/model';

const titleName = (admin: Admin) => `${admin.name}#${admin.id.slice(-4)}`; 

// join admins with models by modelId
const useAdmins = () => {
  const { data: admins = [], mutate } = useSWR<Admin[]>(adminApi.urls.List);
  const { data: models = [] } = useSWR<Model[]>(modelApi.utrls.List);

  const joined = admins.map((admin) => {
    const model = models.find((model) => model.id === admin.modelId);
    return { ...admin, model };
  });

  return {
    admins: joined,
    approve: (id: string) => adminApi.approve(id).then(() => mutate()),
    reject: (id: string) => adminApi.reject(id).then(() => mutate()),
  };
}



const AdminList: React.FC = () => {
  const { admins, approve, reject } = useAdmins();
  return (
    <Container>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>TelegramID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin) => (
              <TableRow key={admin.id}>
                <TableCell>{admin.model?.name}</TableCell>
                <TableCell>{titleName(admin)}</TableCell>
                <TableCell>{admin.chatId}</TableCell>
                <TableCell>
                  <Typography variant="body2" color={admin.approved ? 'primary' : 'error'}>
                    {admin.approved ? 'Approved' : 'Not approved'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={admin.approved ? () => reject(admin.id) : () => approve(admin.id)}
                  >
                    {admin.approved ? 'Reject' : 'Approve'}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdminList;