import React, { useState } from 'react';
import { mutate } from 'swr';
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import * as api from '../../../api/model';
import CompanySelect from '../company/CompanySelect';
import { toast } from 'react-hot-toast';

type RegisterModelButtonProps = Omit<ButtonProps, 'onClick'> & {
  companyId: string;
};

const RegisterModelButton: React.FC<RegisterModelButtonProps> = ({ companyId: initialCompanyId }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  //const [alias, setAlias] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  //const [botLink, setBotLink] = useState('');
  //const [telegramBotSecretKey, setTelegramBotSecretKey] = useState('');
  const [companyId, setCompanyId] = useState(initialCompanyId);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName('');
    //setAlias('');
    setAvatarUrl('');
    //setTelegramBotSecretKey('');
    setCompanyId(initialCompanyId);
  };

  const handleAddModel = async () => {
    if (
      name.trim() === '' ||
      //alias.trim() === '' ||
      avatarUrl.trim() === '' ||
      //botLink.trim() === '' ||
      //telegramBotSecretKey.trim() === '' ||
      !companyId
    ) return;

    try {
      await toast.promise(api.create({
        name,
        //alias,
        avatarUrl,
        //botLink,
        //telegramBotSecretKey,
        companyId,
      }), {
        loading: 'Adding model...',
        success: 'Model added!',
        error: 'Failed to add model',
      });

      mutate(api.utrls.List);
      handleClose();
    } catch (error) {
      console.error('Failed to add model', error);
    }
  };

  const disabled = name.trim() === '' || avatarUrl.trim() === '' || !companyId;
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Register Model
      </Button>
      <Dialog open={open}>
        <DialogTitle>Register a New Model</DialogTitle>
        <DialogContent>
          <CompanySelect value={companyId} onChange={(e) => setCompanyId(e.target.value)} />
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {/*
          <TextField
            margin="dense"
            label="Alias"
            fullWidth
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
          />
          */}
          <TextField
            margin="dense"
            label="Avatar URL"
            fullWidth
            required
            value={avatarUrl}
            onChange={(e) => setAvatarUrl(e.target.value)}
          />
          {/*
          <TextField
            margin="dense"
            label="Bot Link"
            fullWidth
            value={botLink}
            onChange={(e) => setBotLink(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Telegram Bot Secret Key"
            fullWidth
            value={telegramBotSecretKey}
            onChange={(e) => setTelegramBotSecretKey(e.target.value)}
          />
          */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant='contained'>
            Cancel
          </Button>
          <Button onClick={handleAddModel} color="primary" variant='contained' disabled={disabled}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RegisterModelButton;
