import React, { useState } from 'react';
import { Button, List, ListItem, ListItemText, CircularProgress, IconButton, Typography, ListItemProps, FormControlLabel, Switch } from '@mui/material';
import useSWR from 'swr';
import * as api from '../../../api/company';
import { Company } from '../../../types/company';
import EditCompanyDialog from '../../dialogs/EditCompanyDialog';
import { toast } from 'react-hot-toast';
import { Edit } from '@mui/icons-material';
import BlurPaper from '../../dumps/BlutPaper';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import { Link } from 'react-router-dom';
import ExternalLink from '../../dumps/ExternalLink';

type CompanyItemProps = ListItemProps & {
  company: Company;
  onEdit: (company: Company) => void;
  onSwitched: (company: Company) => void;
};
const CompanyItem: React.FC<CompanyItemProps> = ({ company, onEdit, onSwitched, ...props }) => {
  const handleSwitchActivate = async () => {
    try {
      await toast.promise(api.update(company.id, { isActivated: !company.isActivated }), {
        loading: 'Activating company...',
        success: 'Company activated!',
        error: 'Failed to activate company',
      });
      onSwitched(company);
    } catch (error) {
      console.error('Failed to update company', error);
    }
  }

  return (
    <ListItem {...props}
      secondaryAction={
        <>
          <Link to={`/company/${company.id}/models`}>
            <IconButton>
              <Diversity1Icon />
            </IconButton>
          </Link>
          {/*
          <FormControlLabel 
            control={<Switch checked={company.isActivated} onChange={handleSwitchActivate}/>}
            label="Activate"
          />
          */}
          <IconButton onClick={() => onEdit(company)}>
            <Edit />
          </IconButton>
        </>
      }
    >
      <Link to={`/company/${company.id}/models`}>
        <Button>
          <ListItemText primary={company.name} />
        </Button>
      </Link>
      {
        company.botLink ? (
          <ExternalLink href={company.botLink}>
            <Typography variant="body2" color="textSecondary">
              {company.botLink}
            </Typography>
          </ExternalLink>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No bot link
          </Typography>
        )
      }
    </ListItem>
  );
}

const CompanyList = () => {
  const { data = [], error, mutate } = useSWR<Company[]>(api.utrls.List);
  const [editCompany, setEditCompany] = useState<Company | null>(null);

  const handleEdit = (company: Company) => setEditCompany(company);
  const handleEditDialogClose = () => setEditCompany(null);
  const handleEditDialogAccept = async (company: Company) => {
    try {
      await toast.promise(api.update(company.id, company), {
        loading: 'Updating company...',
        success: 'Company updated!',
        error: 'Failed to update company',
      });
      mutate();
    } catch (error) {
      console.error('Failed to update company', error);
    }
    setEditCompany(null);
  };

  if (error) return <div>Failed to load companies</div>;
  if (!data) return <CircularProgress />;

  return (
    <>
      <List>
        {data.map((company, index) => (
          <BlurPaper key={company.id} duration={`${index * 0.1}s`}>
            <CompanyItem company={company} onEdit={handleEdit} onSwitched={() => mutate()} />
          </BlurPaper>
        ))}
      </List>
      {editCompany && (
        <EditCompanyDialog
          open={Boolean(editCompany)}
          onClose={handleEditDialogClose}
          onAccept={handleEditDialogAccept}
          init={editCompany}
        />
      )}
    </>
  );
};

export default CompanyList;
